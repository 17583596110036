import * as React from 'react'
import * as styles from '../assets/styles.module.scss'
import { default as Header } from './common/header'
import { default as Footer } from './common/footer'
import { default as Heroshot } from './common/heroshot'
import { default as Contact } from './homepage/Contact'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import DigitalOcean from '../assets/vectors/PartnerBadges_blue.svg'
import Conekta from '../assets/vectors/conekta.svg'
import Mailchimp from '../assets/vectors/mailchimp.svg'
import Stripe from '../assets/vectors/stripe.svg'
import Fedex from '../assets/vectors/fedex.svg'

export default class Page extends React.Component<{}, {}> {
    public render() {
        return (
            <section className={styles.Container}>
                <Header 
                    title="eCommerce Specialists | Juvasoft"
                    description={`Juvasoft partners with you to create a
                    full ecommerce strategy to increase online sales.`}
                    link="https://juvasoft.com/ecommerce"
                    landing={true}
                 />
                <Heroshot title={`Are you truly selling online?`}
                subtitle={`Juvasoft partners with you to create a
                full ecommerce strategy to increase online sales.`} />

                <div className={styles.Ecommerce}>
                    <div className={styles.Services}>
                        <header>
                            <h1>Our Services</h1>

                            <p className={styles.Subtitle}>
                                We all see headlines about how easy it is to sell online nowadays; however,
                                most of the time, we publish or store online and wait, without much luck, 
                                for our first sales. <strong>We will guide you along the way to make sure
                                your store is successful.</strong>
                            </p>
                        </header>

                        <div className={styles.Service}>
                            <h2>Strategic Consulting</h2>

                            <p>We analyze your business and outline a plan tailored for your business.</p>

                            <ul>
                                <li>eCommerce Consulting</li>
                                <li>Go-to-market Strategy</li>
                                <li>Business Analysis and Strategic Planning</li>
                                <li>Online Store Setup and Launch</li>
                            </ul>
                        </div>

                        <div className={styles.Service}>
                            <h2>Design and UX</h2>

                            <p>We will design you the branding and ecommerce site specially tailored to your business.</p>

                            <ul>
                                <li>Tailored Design</li>
                                <li>Responsive Design (Smartphones, Tablets, etc..)</li>
                                <li>User Experience (UX)</li>
                                <li>Branding</li>
                                <li>Product Photography</li>
                                <li>Corporate Video</li>
                            </ul>
                        </div>

                        <div className={styles.Service}>
                            <h2>Development</h2>

                            <p>
                                We'll develop your online store with the best practices
                                to make sure it performs as best as possible.
                            </p>

                            <ul>
                                <li>FrontEnd Development</li>
                                <li>Performance Optimization</li>
                                <li>Converstion Optimization</li>
                                <li>SEO Optimization</li>
                                <li>Store Management</li>
                                <li>Payment services integration</li>
                                <li>Logistics planning</li>
                            </ul>
                        </div>

                        <div className={styles.Service}>
                            <h2>Training and Support</h2>

                            <p>We will have your back along the way.</p>

                            <ul>
                                <li>Training</li>
                                <li>Monthly Performance Reports</li>
                                <li>Marketing and Social Media</li>
                                <li>Technical Support</li>
                            </ul>
                        </div>

                        <div className={styles.CallToAction}>
                            <AnchorLink offset="0" href="#Contact">
                                Contact us
                            </AnchorLink>
                        </div>
                    </div>

                    <div className={styles.Brands}>
                        <header>
                            <h1>Technology and Services</h1>

                            <p className={styles.Subtitle}>
                                We integrate your store with the best technology and services available.
                                With our strategic partnerships, we make sure to give you the technological
                                support needed by your company.
                            </p>
                        </header>

                        <div className={styles.Grid}>
                            <DigitalOcean />
                            <Conekta />
                            <Fedex />
                            <Mailchimp />
                            <Stripe />
                        </div>
                    </div>
                </div>
                <Contact />
                <Footer />
            </section>
        )
    }
}